import React from 'react';
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    console.log(error);
    console.log(errorInfo);
  }

  render() {

    if (this.state.hasError) {
      // You can render any custom fallback UI
      //return <Maintanance />;
      return (
        <Navigate to="/error"/>
      )
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any
};
export default ErrorBoundary;
