import {gql, useQuery} from "@apollo/client";
import {UserByIdQuery, UserQuery} from "./appQuery";
import SemesterTermFragment from "./fragments/semesterTerm";

export const StudentSemesterTermQuery = gql `        
    ${SemesterTermFragment}
    query SemesterTermQuery($enrollmentId: ID!) {               
      semesterTermByStudentEnrollmentId(enrollmentId: $enrollmentId) { ...SemesterTermFragment }                         
    }
  `;

export const FindSchoolById = (id) => {
  const {data} = useQuery(UserByIdQuery,
    {
      variables: {id},
      fetchPolicy: 'cache-first'
    }
  );

  return data?.studentUserDetailById?.schoolEnrollment.find((it) => it.schoolYear === 2024)?.schoolId;
}

export const FindSchool = () => {
  const {data} = useQuery(UserQuery,
    {
      fetchPolicy: 'cache-first'
    }
  );

  return data?.studentUserDetail?.schoolEnrollment?.find((it) => it.schoolYear === 2024)?.schoolId;
}

export const FindEnrollmentById = (id) => {
  const enrollments = useQuery(UserByIdQuery,
    {
      variables: {id},
      fetchPolicy: 'cache-first'
    }
  );

  return enrollments?.data?.studentUserDetailById?.schoolEnrollment?.find((it) => it.schoolYear === 2024);
}

export const FindStudentRecordById = (id) => {
  const enrollments = useQuery(UserByIdQuery,
    {
      variables: {id},
      fetchPolicy: 'cache-first'
    }
  );

  return enrollments?.data?.studentUserDetailById;
}

export const FindEnrollment = () => {
  const {data} = useQuery(UserQuery,
    {
      fetchPolicy: 'cache-first'
    }
  );

  return data?.studentUserDetail?.schoolEnrollment?.find((it) => it.schoolYear === 2024);
}

export const FindStudent = () => {
  const {data} = useQuery(UserQuery,
    {
      fetchPolicy: 'cache-first'
    }
  );
  return data?.studentUserDetail?.studentRecord.studentId;
}

export const FindStudentByID = (id) => {
  const {data} = useQuery(UserByIdQuery,
    {
      variables: {id},
      fetchPolicy: 'cache-first'
    }
  );
  return data?.studentUserDetailById?.studentRecord.studentId;
}

export const FindTermInfo = (id) => {
  let enrollmentId
  if (id) {
    enrollmentId = id
  } else {
    enrollmentId = FindEnrollment()?.schoolEnrollmentId;
  }

  const termInfo = useQuery(StudentSemesterTermQuery,
    {
      skip: !enrollmentId,
      variables: { enrollmentId },
      fetchPolicy: 'cache-first'
    }
  );

  return termInfo;
}


