import { gql } from "@apollo/client";

const PhoneNumberFragment = gql `
  fragment PhoneNumberFragment on PhoneNumber {
    id
    type
    number
  }
`

export default PhoneNumberFragment