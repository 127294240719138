import { gql, useQuery } from "@apollo/client";
import { FindSchoolById, FindSchool } from "./basicQueries";

const schoolClosings = gql`
  query ($schoolId: ID!, $schoolYear: Int){
    schoolCalendar(schoolId: $schoolId, schoolYear: $schoolYear) {
      id    
      schoolYear
      occurredOn
      reason
      note         
    }
  }
`;

const FindCalender = (id) => {
  let schoolId;
  if (id) {
    schoolId = FindSchoolById(id);
  } else {
    schoolId = FindSchool();
  }

  const closing = useQuery(schoolClosings,
    {
      skip: !schoolId,
      variables: { schoolId, schoolYear: 2024 },
      fetchPolicy: 'cache-and-network'
    }
  );
  return closing;
}

export const FindCalenderBySchoolId = (schoolId) => {

  const closing = useQuery(schoolClosings,
    {
      skip: !schoolId,
      variables: { schoolId },
      fetchPolicy: 'cache-and-network'
    }
  );
  return closing;
}
export default FindCalender
