import { gql } from "@apollo/client";

export default gql `
  fragment AddressFragment on Address {
		id
		city
		state
		streetNumber
		streetName
		streetType
		streetPrefix
		unitNumber
		unitType
		zipCode
  }
`