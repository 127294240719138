import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import {gql, useQuery} from "@apollo/client";

const API_URL = '/api/data/chat/ChatData';

const initialState = {
  chats: [],
  chatContent: 1,
  chatSearch: '',
};

export const ChatSliceApollo = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    getChats: (state, action) => {
      state.chats = action.payload;
    },
    SearchChat: (state, action) => {
      state.chatSearch = action.payload;
    },
    SelectChat: (state, action) => {
      state.chatContent = action.payload;
    },
    sendMsg: {
      reducer: (state, action) => {
        state.chats = state.chats.map((chat) =>
          chat.id === action.payload.id
            ? {
                ...chat,
                ...chat.chatHistory[0][1].to.push(action.payload.chatMsg),
              }
            : chat,
        );
      },

      prepare: (id, chatMsg) => {
        return {
          payload: { id, chatMsg },
        };
      },
    },
  },
});

export const { SearchChat, getChats, sendMsg, SelectChat } = ChatSliceApollo.actions;


const periodsQL = gql`
  query ($schoolId: ID!){
    periods(schoolId: $schoolId) {
      id
      status
      name      
      startTime
      endTime        
    }
  }
`;


export const fetchChats = () => async (dispatch) => {

  try {
    const schoolId = 1;
    const periods = useQuery(periodsQL,
      {
        variables: { schoolId },
        fetchPolicy: 'cache-first'
      }
    );
    console.log(periods);
    const response = await axios.get(`${API_URL}`);
    dispatch(getChats(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export default ChatSliceApollo.reducer;
