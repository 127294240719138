import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loggedInUserId: '',
  impersonatedUserId: '',
  impersonatedUserEmail: '',
  subUserAccounts: [],
  users: []
};

export const UserIdentitySlice = createSlice({
  name: 'userIdentity',
  initialState,
  reducers: {
    impersonateUser: (state, action) => {
      state.impersonatedUserId = action.payload.id;
      state.impersonatedUserEmail = action.payload.email;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    undoImpersonateUser: (state) => {
      state.impersonatedUserId = '';
      state.impersonatedUserEmail = '';
    },
  },
});

export const { impersonateUser, undoImpersonateUser, setUsers } = UserIdentitySlice.actions;


export default UserIdentitySlice.reducer;
