
import {ApolloClient, createHttpLink, InMemoryCache, split} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import AppConfig from "./config";


const httpLink = createHttpLink({
  uri: AppConfig.apolloServerURI
});


// const wsLink = new GraphQLWsLink(createClient({
//   url: '',
//   connectionParams: {
//     authToken:
//   },
// }));

const wsLink = new WebSocketLink(
  new SubscriptionClient("ws://localhost:5000/graphql", {
    connectionParams: {
      authToken: localStorage.getItem('id_token'),
      authorization: localStorage.getItem('id_token'),
    }
  })
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  return {
    headers: {
      ...headers,
      authorization: localStorage.getItem('id_token'),
      "Accept-Encoding": "gzip"
    }
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink)
);



const client = new ApolloClient({
  // Provide required constructor fields
  cache: new InMemoryCache(
    {
      resultCaching: true,
    }
  ),
  //link: authLink.concat(httpLink),
  link: splitLink,
  // Provide some optional constructor fields
  name: 'react-web-client',
  version: '1.3',
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default client
