import { gql } from "@apollo/client";

const PersonContactListFragment = gql `
  fragment PersonContactListFragment on PersonContact {
    id
    contactId
    email
    firstName
    lastName
    middleName
    personId
    relationship
    primaryContact
    mobilePhone
    homePhone
    workPhone
    address
    city
    state
    zipCode
    person {
    id
    lastName
    firstName
    middleName
    }
  }
`

export default PersonContactListFragment;