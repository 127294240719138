import decode from 'jwt-decode'
import AppConfig from '../config'
import {
  ID_TOKEN_KEY,
  PROFILE_KEY,
  LOGIN_PATH,
  REFRESH_TOKEN,
  CHECK_JWT_REFRESH_TIMER,
  JWT_REFRESH_COUNT
} from './constants'

export const ID_REFRESH_KEY = 'refreshToken'
const MAX_JWT_REFRESHES = 60
const JWT_REFRESH_COUNT_KEY = 'jwt_refresh_count'
const REFRESH_INTERVAL = 10000
export const CHECK_JWT_REFRESH_TIMER_KEY = 'check_jwt_refresh_timer'

function getJWTRefreshCount() {
  return parseInt((localStorage.getItem(JWT_REFRESH_COUNT_KEY) || '0'), 10)
}

export function shouldRefreshJWT() {
  return getJWTRefreshCount() < MAX_JWT_REFRESHES
}

function getJWTExpirationDate(token) {
  const decoded = decode(token)
  const date = new Date(0) // The 0 here is the key, which sets the date to the epoch

  if(!decoded.exp) {
    return null
  }

  date.setUTCSeconds(decoded.exp)

  return date
}

export function isJWTExpired(token) {
  const date = getJWTExpirationDate(token)

  if (date === null) {
    return false
  }

  return !(date.valueOf() > new Date().valueOf())
}

export function refreshExpiredJWT() {

  let accessToken

  const client = window.google.accounts.oauth2.initTokenClient({
    client_id: AppConfig.googleClientId,
    scope: AppConfig.googleClientScopes,
    prompt: '',
    callback: (tokenResponse) => {
      accessToken = tokenResponse.access_token;
      localStorage.setItem(ID_TOKEN_KEY, accessToken)
      localStorage.setItem(JWT_REFRESH_COUNT_KEY, getJWTRefreshCount() + 1)
    },
  });

  client.requestAccessToken();

}

export function stopJWTRefreshChecker() {
  return clearInterval(localStorage.getItem(CHECK_JWT_REFRESH_TIMER_KEY))
}

export function resetJWTRefreshCount() {
 return localStorage.setItem(JWT_REFRESH_COUNT_KEY, 0)
}

export const logout = (setUnauthorizedToken) => {
  // If setUnauthorizedToken is true and we have a valid token, store the value for display on the login screen.
  if(setUnauthorizedToken === true) {
    const token = localStorage.getItem(ID_TOKEN_KEY)
    if(token !== null) {
      localStorage.setItem('UNAUTHORIZED_TOKEN', token)
    }
  }

  // Clear user token and profile data from localStorage
  localStorage.removeItem(ID_TOKEN_KEY)
  localStorage.removeItem(REFRESH_TOKEN)
  localStorage.removeItem(CHECK_JWT_REFRESH_TIMER)
  localStorage.removeItem(JWT_REFRESH_COUNT)
  localStorage.removeItem(PROFILE_KEY)
  localStorage.removeItem('UNAUTHORIZED_TOKEN')

  // Reset the session storage (clean out sticky filters)
  sessionStorage.clear()

  stopJWTRefreshChecker()
  resetJWTRefreshCount()

  //store.dispatch(logoutUser())

  // Relocate the the login page.
  window.location = LOGIN_PATH
}

export const getNewAccessToken = () => {
  // get new access token using refresh token
  const payloadForAccessToken = {
    refresh_token: localStorage.getItem(ID_REFRESH_KEY),
  };

  fetch(`${AppConfig.authServerURI}/refresh`, {
    method: 'POST',
    body: JSON.stringify(payloadForAccessToken),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  })
  .then((res) => {
    return res.json();
  }).then((res) => {
    decode(res.response.id_token)
    localStorage.setItem(ID_TOKEN_KEY, res.response.id_token)
    localStorage.setItem(JWT_REFRESH_COUNT_KEY, getJWTRefreshCount() + 1)
  }).catch((err) => {
    console.log('err: ', err);
    logout(true);
  } );
};

export function checkJWTRefresh() {
  const token = localStorage.getItem(ID_TOKEN_KEY)

  if (token === "undefined" || !shouldRefreshJWT()) {
    return logout()
  }

  if (token !== null && isJWTExpired(token)) {
    const data = getNewAccessToken();
    return data
  }

  return null
}

// export const checkJWTRefresh = () => {
//   const token = localStorage.getItem(ID_TOKEN_KEY)
//
//   // if (!shouldRefreshJWT()) {
//   if (isJWTExpired(token)) {
//     logout()
//   }
//   // }
//
//   //if (isJWTExpired(token)) {
//   //   refreshExpiredJWT()
//   //}
// }

export function startJWTRefreshChecker() {
  // start the refresh checker (checking once / sec)
  return localStorage.setItem(CHECK_JWT_REFRESH_TIMER_KEY, setInterval(checkJWTRefresh, REFRESH_INTERVAL))
}









