import { Col, Row, Spinner} from "reactstrap";
import {Navigate, useParams} from "react-router-dom";
import SubjectStrands from "../dashboard/SubjectStrandsOverview";
import GradesTable from "../dashboard/GradesOverView";
import {isElementary} from "../../../helpers/viewHelper";
import {FindEnrollmentById} from "../../../graphql/basicQueries";
import {ParentDetails} from "../../../graphql/appQuery";

const StudentGradesContainer = () => {
  const { studentPersonId } = useParams();
  const { data } = ParentDetails();
  const enrollment = FindEnrollmentById(studentPersonId);

  const student = data.parentUserDetails?.myStudents.find((it) => it.id === studentPersonId);
  if (student === undefined || student === null)
    return (<Navigate to="/error"/>)

  if (enrollment) {
    const gradeLevel = enrollment?.gradeLevel;
    return (
      <div>
        <Row>
          {isElementary(gradeLevel) ? (
            <Col lg="12" sm="12">
              <SubjectStrands/>
            </Col>
          ) : (
            <Col lg="12" sm="12">
              <GradesTable/>
            </Col>
          )}
        </Row>
      </div>
    );
  }

  return <Spinner className="me-2" color="light" />;
};

export default StudentGradesContainer;
