import {gql} from "@apollo/client";

const ParentUserAccountFragment = gql`
  fragment ParentUserAccountFragment on ParentUserAccount {    
    id    
    email  
    firstName   
    lastName  
    picture
    myStudents {
      id        
      firstName      
      lastName
      email
      picture
      gradeLevel    
      status   
      schoolId  
      schoolEnrollmentId  
    }
  } 
`

const StudentUserAccountFragment = gql`
  fragment StudentUserAccountFragment on StudentUserAccount {  
      id
      email
      salutation
      firstName
      middleName
      lastName
      gender
      dateOfBirth            
      studentRecord {
        stateID
        studentId
      }
      schoolEnrollment {
        schoolEnrollmentId
        schoolYear
        gradeLevel  
        schoolId  
      }    
      impersonatedById   
  }
`

export {StudentUserAccountFragment, ParentUserAccountFragment}
