import {gql, useQuery} from "@apollo/client";
import {FindEnrollment, FindEnrollmentById} from "./basicQueries";

const attendanceQueries = gql`
  query ($enrollmentId: ID!){
    studentInformationAttendance(enrollmentId: $enrollmentId) {
      occurredOn
      courseName
      verifiedCode
      eventType
      meetingPeriod
      id     
      sectionEnrollmentId       
    }
  }
`;

export const AbsenceQuery = gql `    
    query studentAbsence($enrollmentId: ID!, $schoolYear: Int ) {
      studentAbsence(enrollmentId: $enrollmentId, schoolYear: $schoolYear) {
        sum
      }
    }
  `;
export const TardyQuery = gql `    
    query studentTardy($enrollmentId: ID!, $schoolYear: Int ) {
      studentTardy(enrollmentId: $enrollmentId, schoolYear: $schoolYear) {
        sum
      }
    }
  `;

export const CombinedAbsenceTardyQuery = gql `    
    query combinedAbsenceTardyQuery($enrollmentId: ID!, $schoolYear: Int ) {
      studentAbsence(enrollmentId: $enrollmentId, schoolYear: $schoolYear) {
        sum
      }
      studentTardy(enrollmentId: $enrollmentId, schoolYear: $schoolYear) {
        sum
      }
    }
  `;


const FindAttendance = (id) => {
  let enrollmentId;

  if (id) {
    enrollmentId = FindEnrollmentById(id)?.schoolEnrollmentId;
  } else {
    enrollmentId = FindEnrollment()?.schoolEnrollmentId;
  }

  const attendance = useQuery(attendanceQueries,
    {
      skip: !enrollmentId,
      variables: { enrollmentId },
      fetchPolicy: 'cache-and-network'
    }
  );

  return attendance;
}


export const FindAttendanceByEnrollmentId = (enrollmentId) => {

  const attendance = useQuery(attendanceQueries,
    {
      skip: !enrollmentId,
      variables: { enrollmentId },
      fetchPolicy: 'cache-and-network'
    }
  );

  return attendance;
}

export const FindAbsenceSummary = (id) => {

  let enrollmentId;

  if (id) {
    enrollmentId = FindEnrollmentById(id)?.schoolEnrollmentId;
  } else {
    enrollmentId = FindEnrollment()?.schoolEnrollmentId;
  }

  const absence = useQuery(AbsenceQuery,
    {
      skip: !enrollmentId,
      variables: { enrollmentId },
      fetchPolicy: 'cache-and-network'
    }
  );

  const tardy = useQuery(TardyQuery,
    {
      skip: !enrollmentId,
      variables: { enrollmentId },
      fetchPolicy: 'cache-and-network'
    }
  );
  return (
    { tardy, absence }
  ) ;
}


export const FindAbsenceSummaryByEnrollment = (id) => {
  let enrollmentId;

  if (id) {
    enrollmentId = id
  } else {
    enrollmentId = FindEnrollment()?.schoolEnrollmentId;
  }

  const combined = useQuery(CombinedAbsenceTardyQuery,
    {
      skip: !enrollmentId,
      variables: { enrollmentId },
      fetchPolicy: 'cache-and-network'
    }
  );

  return combined
}
export default FindAttendance
