import React, { useState } from 'react';
import {
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  CardTitle, CardText
} from 'reactstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import './calendar_style.scss';

moment.locale('en-GB');
const localizer = momentLocalizer(moment);


const StudentCalendar = (closing) => {
  let calevents = [];
  if (closing.data) {
    calevents = closing.data.schoolCalendar.map((it) => ({
      title: it.reason,
      note: it.note,
      allDay: true,
      start: new Date(it.occurredOn),
      end: new Date(it.occurredOn)
    }))
  };

  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState('');
  // const [setUpdate] = useState();

  const viewEvent = (event) => {
    setOpen(true);

    setTitle(`${event.title} - ${event.note}`);
    setStartDate(event.start);
  };

  const handleClose = () => {
    setOpen(false);
    setTitle('');
    setStartDate('');
    // setUpdate(null);
  };

  const eventColors = (event) => {
    if (event.color) {
      return { className: `event-${event.color}` };
    }
    return { className: `event-default` };
  };

  return (
    <>
      <div>
        <Card>
          <CardBody>
            <Calendar
              selectable
              events={calevents}
              defaultView="month"
              scrollToTime={new Date(1970, 1, 1, 6)}
              defaultDate={new Date()}
              localizer={localizer}
              style={{ height: 'calc(100vh' }}
              onSelectEvent={(event) => viewEvent(event)}
              //onSelectSlot={(slotInfo) => addNewEventAlert(slotInfo)}
              eventPropGetter={(event) => eventColors(event)}
            />
            <Modal isOpen={open}>
              <ModalHeader toggle={handleClose}>Event Details</ModalHeader>
                <ModalBody>
                  <Card body className="text-center">
                    <CardTitle tag="h5">{moment(startDate).format('MM-DD-YYYY')}</CardTitle>
                    <CardText>
                      {title}
                    </CardText>
                    <div>
                      <Button color="light-danger" onClick={handleClose}>Close</Button>
                    </div>
                  </Card>
                </ModalBody>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default StudentCalendar;
