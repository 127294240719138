import {Navigate, useParams} from "react-router-dom";
import {Spinner} from "reactstrap";

import moment from "moment";
import {FindAttendanceByEnrollmentId} from "../../../graphql/attendanceQueries";
import {FindPeriodBySchoolId} from "../../../graphql/periodsQueries";

import StudentAttendanceDetails from "../../components/attendance/attendanceDetails";
import {ParentDetails} from "../../../graphql/appQuery";

const StudentAttendanceContainer = () => {

  const { schoolEnrollmentId, schoolId } = useParams();
  const attendance = FindAttendanceByEnrollmentId(schoolEnrollmentId);
  const periods = FindPeriodBySchoolId(schoolId);
  const parentData = ParentDetails();

  if (parentData?.data) {
    const student = parentData.data.parentUserDetails?.myStudents.find((it) => it.schoolEnrollmentId === schoolEnrollmentId);

    if (student === undefined || student === null)
      return (<Navigate to="/error"/>)
  }

  const data = [];
  if (attendance.data && periods.data) {
    for (let i = 0; i < attendance.data.studentInformationAttendance.length; i++) {
      const periodObj = periods.data.periods.find(it => it.id.toString() === attendance.data.studentInformationAttendance[i].meetingPeriod)
      data.push(
        ({
          occurredOn: moment(attendance.data.studentInformationAttendance[i].occurredOn).format('MM-DD-YYYY'),
          courseName : attendance.data.studentInformationAttendance[i].courseName,
          period: periodObj.name,
          eventType: attendance.data.studentInformationAttendance[i].eventType
        })
      )
    }
  }
  if (attendance?.data && parentData?.data) {
    return <StudentAttendanceDetails {...data}/>
  }
  return <Spinner color="primary" />
};

export default StudentAttendanceContainer;
