
export const isElementary = (gradeLevel) => {
  switch (gradeLevel) {
    case 'PREK':
    case 'KG':
    case '1ST':
    case '2ND':
    case '3RD':
    case '4TH':
    case '5TH':
      return true;
    default:
      return false;
  }
}

export const findIndex = (term) => {
  let index = 0;
  switch(term) {
    case 'q1':
      index = 0;
      break;
    case 'q2':
      index = 1;
      break;
    case 's1':
      index = 2;
      break;
    case 'q3':
      index = 3;
      break;
    case 'q4':
      index = 4;
      break;
    case 's2':
      index = 5;
      break;
    case 's3':
      index = 6;
      break;
    case 'yr':
      index = 7;
      break;
    default:
      index = -1;
  }
  return index;
}


