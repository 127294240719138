import { gql } from "@apollo/client";
import SchoolGroupFragment from "./schoolGroup";

const SchoolGroupSettingFragment = gql `
  ${SchoolGroupFragment}
  fragment SchoolGroupSettingFragment on SchoolGroupSetting {
    id
    currentSchoolGroup { ...SchoolGroupFragment }
    currentSchool { id name }
    currentSchoolYear
  }
`

export default SchoolGroupSettingFragment