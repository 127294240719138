import { gql } from "@apollo/client";

const SemesterTermFragment = gql `
  fragment SemesterTermFragment on SemesterTerm {
    id
    yearStart
    yearEnd
    s1Start
    s1End
    s2Start
    s2End
    q1Start
    q1End
    q2Start
    q2End
    q3Start
    q3End
    q4Start
    q4End,
    s3Start,
    s3End
  }
`

export default SemesterTermFragment