import { gql } from "@apollo/client";

const SchoolGroupFragment = gql `
  fragment SchoolGroupFragment on SchoolGroup {
    id
    status
    name
    gradeLevels
  }
`

export default SchoolGroupFragment