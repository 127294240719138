// const scope =
//   "profile " +
//   "email " +
//   "https://www.googleapis.com/auth/calendar " +
//   "https://www.googleapis.com/auth/drive " +
//   "https://mail.google.com/ ";

const AppConfig = {
  apolloServerURI: process.env.REACT_APP_APOLLO_SERVER_URI,
  authServerURI: process.env.REACT_APP_AUTH_SERVER_URI,
  apolloWSServerURI: process.env.REACT_APP_APOLLO_WS_SERVER_URI,
  backendUri: process.env.REACT_APP_BACKEND_URI,
	backendWsUri: process.env.REACT_APP_BACKEND_WS_URI,
  //googleClientId: '82268063376-6ovvte7gqur48as92onchbs80j93vv4o.apps.googleusercontent.com',
  googleClientId: '231216824608-3f357bd05qeuop5hgdbhhphio9iqojcv.apps.googleusercontent.com',
  googleClientScopes: 'profile email',
  redirectUrl: process.env.REACT_APP_REDIRECT_URL
}

export default AppConfig
