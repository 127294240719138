import React from 'react';
import {Button, Card, CardTitle, Col, FormGroup, Label, Row} from 'reactstrap';

const ParentRegistration = () => {
  return (
   <Row>
      <Col md="12">
          <Card>
            <CardTitle tag="h4" className="border-bottom bg-success p-3 mb-0 text-white">
              Next Year Registration Survey
            </CardTitle>

        <FormGroup>
          <br/>
          <Label>
           Are you planning to enroll your kid/kids for next school year?
          </Label>
        </FormGroup>

            <FormGroup>
              <Button type="button" className="btn btn-geePrimary ms-2" >
                No
              </Button>
              <Button type="button" className="btn btn-success ms-2" >
                Yes
              </Button>
            </FormGroup>
          </Card>

      </Col>
   </Row>


  );
};

export default ParentRegistration;
