
import {gql, useQuery} from "@apollo/client";
import PersonFragment from "./fragments/person";
import PersonContactListFragment from "./fragments/personContact";
import { ParentUserAccountFragment } from "./fragments/account"

export const PeopleContactsQuery = gql `
    ${PersonContactListFragment}
    query PersonContactList($personId: ID!) {
      peopleContacts(personId: $personId) { ...PersonContactListFragment }
    }
  `;

export const AppQuery = gql `    
    ${PersonFragment}    
    
    query AppQuery {      
      me { ...PersonFragment }           
      schools { 
        id
        name
      }               
    }
  `;

export const UserByIdQuery = gql `          
  query($id: ID!) {      
    studentUserDetailById(id: $id)  { 
      id
      email
      salutation
      firstName
      middleName
      lastName
      gender
      dateOfBirth            
      studentRecord {
        stateID
        studentId
      }
      schoolEnrollment {
        schoolEnrollmentId
        schoolYear
        gradeLevel  
        schoolId  
      }    
      impersonatedById   
    }      
  }
  `;

export const UserQuery = gql `            
    query {      
      studentUserDetail {
        id
        email
        salutation
        firstName
        middleName
        lastName
        gender
        dateOfBirth            
        studentRecord {
          stateID
          studentId
        }
        schoolEnrollment {
          schoolEnrollmentId
          schoolYear
          gradeLevel  
          schoolId  
        }    
        impersonatedById     
      }           
    }
  `;

export const RegistrationQuery = gql `
    query studentParentRegistrationConsent {
      studentParentRegistrationConsent {
        id
        schoolYear
        status
      }
    }
  `;


export const RegistrationMutation = gql `

mutation confirmRegistrationForNextYear {
    confirmRegistrationForNextYear {
        id
        schoolYear
        status
    }
  }
    
  `;


export const EnrollmentQuery = gql `
    query studentSchoolEnrollment($personId: ID!, $schoolYear: Int ) {
      studentSchoolEnrollment(personId: $personId, schoolYear: $schoolYear) {
        id
        gradeLevel
        studentId
      }
    }
  `;

export const PeopleQuery = gql `    
    query people($nameOrEmail: String) {
      people(nameOrEmail: $nameOrEmail) {
        id
        email
        firstName
        gender
        lastName
        picture
        stateID
        middleName
      }
    }
  `;

export const ImpersonateMutation = gql`
  ${PersonFragment}

  mutation Impersonate($personId: ID!) {
    impersonate(personId: $personId) {
      ...PersonFragment
    }
  }
`

export const UndoImpersonationMutation = gql`
  ${PersonFragment}

  mutation UndoImpersonation {
    undoImpersonation {
      ...PersonFragment
    }
  }
`

export const UserDetails = () => {
  const me = useQuery(AppQuery,
    {
      fetchPolicy: 'cache-first'
    }
  );
  return me;
}

export const ParentAccountQuery = gql `   

    ${ParentUserAccountFragment} 
    query getParentDetailsQuery {
      parentUserDetails {     
        ...ParentUserAccountFragment  
      }
    }
  `;

export const LoggedInUserId = () => {
  const {data} = UserDetails();
  return data?.me?.id
}

export const ParentDetails = () => {

    const parent = useQuery(ParentAccountQuery,
      {
        fetchPolicy: 'cache-first'
      }
    )
    return parent;

}

export const RegistrationDetails = () => {

  const registration = useQuery(RegistrationQuery,
    {
      fetchPolicy: 'cache-first'
    }
  )
  return registration;

}

