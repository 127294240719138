import {gql, useQuery} from "@apollo/client";
import {FindSchool, FindSchoolById} from "./basicQueries";

const periodsQL = gql`
  query GetPeriods($schoolId: ID!){
    periods(schoolId: $schoolId) {
      id
      status
      name      
      startTime
      endTime        
    }
  }
`;

const FindPeriod = (id) => {
  let schoolId;
  if (id) {
    schoolId = FindSchoolById(id);
  } else {
    schoolId = FindSchool();
  }
  const periods = useQuery(periodsQL,
    {
      skip: !schoolId,
      variables: {schoolId},
      fetchPolicy: 'cache-first'
    },
  );
  return periods;
}

const FindPeriodBySchoolId = (schoolId) => {

  const periods = useQuery(periodsQL,
    {
      skip: !schoolId,
      variables: {schoolId},
      fetchPolicy: 'cache-first'
    },
  );
  return periods;
}

export { FindPeriodBySchoolId };
export default FindPeriod ;
