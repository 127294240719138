import {useParams} from "react-router-dom";
// import {Spinner} from "reactstrap";

import FindCalender from "../../../graphql/schoolClosings";
import StudentCalendar from "../../components/calendar/calendar";

const StudentCalendarContainer = () => {

  const { studentPersonId } = useParams();
  const closing = FindCalender(studentPersonId);
  // if (closing?.data) {
    return <StudentCalendar {...closing}/>
  // }
  // return <Spinner color="primary" />
};

export default StudentCalendarContainer;
