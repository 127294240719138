import React from 'react';
import { Table } from 'reactstrap';
import SimpleBar from "simplebar-react";
import {useParams} from "react-router-dom";
import DashCard from '../../../components/dashboard/dashboardCards/DashCard';
import { FindGrades } from "../../../graphql/gradeQueries";
import { findIndex } from "../../../helpers/viewHelper";

const GradesTable = () => {

  const { studentPersonId } = useParams();
  const grades = FindGrades(studentPersonId);

  const map = new Map();
  if (grades.data) {
    const informations = grades.data.studentInformations.filter((it) => it.schoolYear === 2024);
    for (let i = 0; i < informations.length; i++) {
      const key = informations[i].courseName;
      const value = map.get(key);
      if (value) {
        if (informations[i].mark) {
          value.grade[findIndex(informations[i].term)] = informations[i].mark;
        }
        //map.put(key, fromMap);
      } else {
        const entry = {
          id: informations[i].sectionEnrollmentId,
          courseName: informations[i].courseName,
          grade: ['-','-','-','-','-', '-','-','-']
        };
        if (informations[i].mark) {
          entry.grade[findIndex(informations[i].term)] = informations[i].mark
        }
        map.set(key, entry) ;
      }
    }
  }


  return (
    <DashCard title="Grades" subtitle="">
      <SimpleBar style={{height: (map.values().length * 40) + 100}}>
      {/*<SimpleBar style={{ height: '420px' }}>*/}
      <div className="table-responsive">
        <Table className="text-truncate mt-3 align-middle border" responsive>
          <thead>
          <tr>
            <th>Subject</th>
            {/*<th className="bg-light">Q1</th>*/}
            {/*<th >Q2</th>*/}
            <th className="bg-light">S1</th>
            {/*<th >Q3</th>*/}
            {/*<th className="bg-light">Q4</th>*/}
            <th >S2</th>
            <th className="bg-light">S3</th>
          </tr>
          </thead>

          <tbody>
          {Array.from(map.values()).map((v) => (
            <tr key={v.id}>
              <th scope="row"><div>{v.courseName}</div></th>
              {/*<td className="bg-light">{v.grade[0]}</td>*/}
              {/*<td >{v.grade[1]}</td>*/}
              <td className="bg-light">{v.grade[2]}</td>
              {/*<td >{v.grade[3]}</td>*/}
              {/*<td className="bg-light">{v.grade[4]}</td>*/}
              <td >{v.grade[5]}</td>
              <td className="bg-light">{v.grade[6]}</td>
            </tr>

          ))}
          </tbody>
        </Table>
      </div>
      </SimpleBar>
    </DashCard>
  );
};

export default GradesTable;
