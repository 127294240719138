/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */

import React, { Suspense, useEffect } from 'react';
import {Spinner} from "reactstrap";
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  GEENotLoggedInRoutes,
  GEEStudentRoutes,
  getParentRoutes
} from './routes/GEERouter';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
import { isLoggedIn } from './helpers/authHelper'
import { startJWTRefreshChecker} from './helpers/jwtHelper'
import {ParentDetails, UserDetails} from "./graphql/appQuery";
import ErrorBoundary from "./views/components/error/errorBoundary";
//import {FindEnrollment} from "./graphql/basicQueries";

const App = () => {

  useEffect(() => {
    startJWTRefreshChecker();
  }, []);

  const {data, loading} = ParentDetails();
  //const enrollment = FindEnrollment()

  const routingNotLoggedIn = useRoutes(GEENotLoggedInRoutes);
  const parentLoggedIn = useRoutes(getParentRoutes(data?.parentUserDetails?.myStudents))
  const studentLoggedIn = useRoutes(GEEStudentRoutes);
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);

  const IsParent = () => {
    const result = data?.parentUserDetails?.myStudents?.length > 0
    return result;
  }

  // const IsStudent = () => {
  //   if (enrollment === false || enrollment === undefined)
  //     logout()
  //   return enrollment;
  // }

  if (isLoggedIn()) {
    UserDetails();
  }

  if (loading)
    return <Spinner className="me-2" color="light" />;

  return (
    <Suspense fallback={<Loader />}>

      <ErrorBoundary>
      <div
        className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
        dir={direction ? 'rtl' : 'ltr'}
      >
        <ThemeSelector />
        { isLoggedIn() === false ? routingNotLoggedIn :
          IsParent()? parentLoggedIn: studentLoggedIn}
      </div>
      </ErrorBoundary>
    </Suspense>
  );
};

export default App;
