import { gql } from "@apollo/client";
import AddressFragment from "./address";
import PhoneNumberFragment from "./phoneNumber";
import SchoolGroupSettingFragment from "./schoolGroupSetting";

const PersonFragment = gql`
  ${AddressFragment}
  ${PhoneNumberFragment}
  ${SchoolGroupSettingFragment}

  fragment PersonFragment on Person {
    id
    impersonatedById
    email
    salutation
    firstName
    middleName
    lastName
    gender
    dateOfBirth
    picture
    phoneNumbers { ...PhoneNumberFragment }
    addresses { ...AddressFragment }
    schoolGroupRoles { id type }
    schoolRoles { id type }
    schoolGroupSetting { ...SchoolGroupSettingFragment }    
  }
`
export default PersonFragment