// import {Col, Row} from "reactstrap";
// import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import ReactTable from "react-table-v6";
import ComponentCard from "../../../components/ComponentCard";
import 'react-table-v6/react-table.css';


const StudentAttendanceDetails = (attendance) => {
  return (
    <div>

      {/*<Row>*/}
      {/*  <Col md="12">*/}
      {/*    <ComponentCard title="Attendance Details">*/}
      {/*      <BootstrapTable*/}
      {/*        hover*/}
      {/*        search*/}
      {/*        data={Object.values(attendance)}*/}
      {/*        pagination*/}
      {/*        tableHeaderClass="mb-0"*/}
      {/*      >*/}

      {/*        <TableHeaderColumn width="100" dataField="occurredOn" isKey>*/}
      {/*          OccurredOn*/}
      {/*        </TableHeaderColumn>*/}
      {/*        <TableHeaderColumn width="100" dataField="period">*/}
      {/*          Period*/}
      {/*        </TableHeaderColumn>*/}
      {/*        <TableHeaderColumn width="100" dataField="courseName">*/}
      {/*          Course*/}
      {/*        </TableHeaderColumn>*/}
      {/*        <TableHeaderColumn width="100" dataField="eventType">*/}
      {/*          Period*/}
      {/*        </TableHeaderColumn>*/}
      {/*      </BootstrapTable>*/}
      {/*    </ComponentCard>*/}
      {/*  </Col>*/}
      {/*</Row>*/}

      <ComponentCard title="Attendance">
        <ReactTable
          columns={[
            {
              Header: 'OccurredOn',
              accessor: 'occurredOn',
            },
            {
              Header: 'Period',
              accessor: 'period',
            },
            {
              Header: 'Course',
              accessor: 'courseName',
            },
            {
              Header: '',
              accessor: 'eventType'
            }
          ]}
          defaultPageSize={5}
          showPaginationBottom
          className="-striped -highlight"
          data={Object.values(attendance)}
          filterable
        />
      </ComponentCard>
    </div>
  );
}


export default StudentAttendanceDetails;
