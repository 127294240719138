import {gql, useQuery} from "@apollo/client";
import {FindStudentByID, FindStudent} from "./basicQueries";

const ScoreBasedMarksQuery = gql`
  query {
    scoreBasedMarks {
      id
      mark
      requiresRetake
      honorPoints
      affectsGpa
    }
  }
`;

const studentInformations = gql`
  query ($studentId: ID!){
    studentInformations(studentId: $studentId) {
      id
      mark
      courseName
      term
      schoolYear
      includeInTranscripts
      credit
      schoolEnrollmentsId
      sectionEnrollmentsId
    }
  }
`;

const courseAssignment = gql`
  query ($sectionEnrollmentId: ID!, $schoolYear: Int ){
    courseAssignments(sectionEnrollmentId: $sectionEnrollmentId, schoolYear: $schoolYear) {
      id
      name
      duedate      
    }
  }
`;

const studentStrandsQuery = gql`
  query ($studentId: ID!){
    studentStrands(studentId: $studentId) {
      id
      term
      mark
      name
      isWorkHabit
      subjectStrandName
      schoolYear
      schoolEnrollmentsId
      sectionEnrollmentsId
    }
  }
`;

const progressQuery = gql`
  query ($studentId: ID!){
    studentProgress(studentId: $studentId) {
      id
      studentId
      firstName
      lastName
      listedAs
      status
      enrolledOn
      percentByTime
      percentExpected
      week
      week1
      Weeks4    
      lastActivity_at 
      sectionEnrollmentId  
    }
  }
`;

const progressByEnrollmentQuery = gql`
  query ($studentId: ID!){
    studentProgressByEnrollmentId(enrollmentId: $enrollmentId) {
      id
      studentId
      firstName
      lastName
      listedAs
      status
      enrolledOn
      percentByTime
      percentExpected
      week
      week1
      Weeks4    
      lastActivity_at 
      sectionEnrollmentId  
    }
  }
`;



export const FindScoreMarks = () => {

  const scores = useQuery(ScoreBasedMarksQuery,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  return scores;

}

export const FindGrades = (id) => {

  let studentId;
  if (id) {
    studentId = FindStudentByID(id);
  } else {
    studentId = FindStudent()
  }

  const grades = useQuery(studentInformations,
    {
      skip: !studentId,
      variables: { studentId },
      fetchPolicy: 'cache-and-network'
    }
  );

  return grades;
}


export const FindAssignmentBySectionEnrollment = (sectionEnrollmentId) => {

  const assignments = useQuery(courseAssignment,
    {
      variables: { sectionEnrollmentId, schoolYear: 2024 },
      fetchPolicy: 'cache-and-network'
    }
  );

  return assignments;
}

export const FindSubjectStrands = (id) => {

  let studentId;
  if (id) {
    studentId = FindStudentByID(id);

  } else {
    studentId = FindStudent();
  }

  const subjectStrands = useQuery(studentStrandsQuery,
    {
      skip: !studentId,
      variables: { studentId },
      fetchPolicy: 'cache-and-network'
    }
  );

  return subjectStrands;
}


export const FindProgress = (id) => {

  let studentId;
  if (id) {
    studentId = FindStudentByID(id);

  } else {
    studentId = FindStudent();
  }

  const progress = useQuery(progressQuery,
    {
      skip: !studentId,
      variables: { studentId },
      fetchPolicy: 'cache-and-network'
    }
  );

  return progress;
}


export const FindProgressByEnrollment = (enrollmentId) => {


  const progress = useQuery(progressByEnrollmentQuery,
    {
      skip: !enrollmentId,
      variables: { enrollmentId },
      fetchPolicy: 'cache-and-network'
    }
  );

  return progress;
}






