
//import _ from 'lodash'
import decode from 'jwt-decode'
import LogRocket from 'logrocket'
import {ID_REFRESH_KEY, isJWTExpired} from './jwtHelper'
import {
  ID_TOKEN_KEY,
  DEFAULT_REDIRECT_PATH,
  LOGIN_PATH,
  REDIRECT_AFTER_LOGIN_KEY,
  //REFRESH_TOKEN,
  //CHECK_JWT_REFRESH_TIMER, PROFILE_KEY
} from './constants'
import AppConfig from "../config";

export const isLoggedIn = () => {
  // Checks if there is a saved token and it's still valid
  const token = localStorage.getItem(ID_TOKEN_KEY)
  if (token === null || token === "undefined") {
    return false
  }
  return !!token && !isJWTExpired(token)
}

const redirectAfterLogin = (replace) => {
  const url = localStorage.getItem(REDIRECT_AFTER_LOGIN_KEY)

  if (url){
    localStorage.removeItem(REDIRECT_AFTER_LOGIN_KEY)
    replace({ pathname: url })
  }
}

// onEnter callback to require user *is* logged in
export const requireLogin = (nextState, replace) => {
  if (!isLoggedIn()) {
    localStorage.setItem(REDIRECT_AFTER_LOGIN_KEY, nextState.location.pathname)
    replace({ pathname: LOGIN_PATH })
  } else {
    redirectAfterLogin(replace)
  }
}

// onEnter callback to require user *isn't* logged in
export const requireNoLogin = (nextState, replace) => {
  if (isLoggedIn()) {
    replace({ pathname: DEFAULT_REDIRECT_PATH })
  }
}

export const onLoginSuccess = (response) => {
  // save the user token
  //localStorage.setItem(ID_TOKEN_KEY, response.tokenId)
  localStorage.setItem(ID_TOKEN_KEY, response.credential)
  // This is a valid login, so remove the UNAUTHORIZED_TOKEN if there is one.
  localStorage.removeItem('UNAUTHORIZED_TOKEN')

  // only id the user to logrocket when we are not in dev.
  if (process.env.NODE_ENV !== 'development') {
    const token = localStorage.getItem(ID_TOKEN_KEY)
    const { name, email } = decode(token)

    LogRocket.identify(email, { name, email })
  }

  // Reload the page to the intended route.
  window.location.reload(true)
}

export const getRefreshToken = (code) => {
  // get refresh token using authorization code
  const payload = {
    code,
    redirect_uri: AppConfig.redirectUrl,
  };

  fetch(`${AppConfig.authServerURI}/authenticate`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  }).then((res) => {
    console.log("getRefreshToken response")
    return res.json()
  }).then((response) => {
    console.log(`getRefreshToken response ${response.response.id_token}`)
    localStorage.setItem(ID_TOKEN_KEY, response.response.id_token)
    localStorage.setItem(ID_REFRESH_KEY, response.response.refresh_token)
    window.location.reload(true)
  })
    .catch((err) => console.log('err: ', err));
};

export const getCode = () => {
  const client = window.google.accounts.oauth2.initCodeClient({
    client_id: AppConfig.googleClientId,
    scope: 'profile email',
    ux_mode: 'popup',
    callback: (response) => {
      localStorage.setItem('code', response.code)
      console.log(`code ${response.code}`)
      getRefreshToken(response.code);
    }
  });
  client.requestCode();
}





export const onLoginFailure = (error) => {
  console.error('Google sign-in failed', error)
}

export default class CurrentUser {
  constructor(person) {
    this.personId = person.id
    this.email = person.email
    this.firstName = person.firstName
    this.lastName = person.lastName
    this.picture = person.picture
    this.schoolGroupSetting = person.schoolGroupSetting
    // this.schoolGroupRoles = _.map(person.schoolGroupRoles, 'type')
    // this.schoolRoles = _.map(person.schoolRoles, 'type')
    this.impersonatedById = person.impersonatedById
  }

  get currentSchool() {
    return this.schoolGroupSetting.currentSchool
  }

  get currentSchoolGroup() {
    return this.schoolGroupSetting.currentSchoolGroup
  }

  // hasAnyOfTheseRoles(roles) {
  //   return _.intersection(roles, this.schoolRoles).length > 0 || _.intersection(roles, this.schoolGroupRoles).length > 0
  // }
  //
  // get isAdmin() {
  //   return _.includes(this.schoolGroupRoles, 'admin')
  // }
  //
  // get isOfficeManager() {
  //   return _.includes(this.schoolRoles, 'office-manager')
  // }
  //
  // get isTeacher() {
  //   return _.includes(this.schoolGroupRoles, 'teacher')
  // }
  //
  // get isCounselor() {
  //   return _.includes(this.schoolRoles, 'counselor')
  // }
  //
  // get isPrincipal() {
  //   return _.includes(this.schoolRoles, 'principal')
  // }
  //
  // get isSuperAdmin() {
  //   return _.includes(this.schoolGroupRoles, 'superAdmin')
  // }
  //
  // get isCurriculumCoordinator() {
  //   return _.includes(this.schoolRoles, 'curriculum-coodinator')
  // }
  //
  // get isAdvisor() {
  //   return _.includes(this.schoolRoles, 'advisor')
  // }
  //
  // get isGeePrep() {
  //   return _.includes(this.schoolRoles, 'geePrepTeacher')
  // }
  //
  //
  // get canManageEnrollment() {
  //   return this.isAdmin || this.isOfficeManager ||  this.isCounselor || this.isPrincipal || this.isSuperAdmin
  // }
  //
  // get canManageScheduling() {
  //   return this.canManageEnrollment
  // }
  //
  // get canManageAttendance() {
  //   return this.isAdmin || this.isPrincipal || this.isOfficeManager || this.isSuperAdmin
  // }
  //
  // get canManageGrades() {
  //   return this.isSuperAdmin || this.isGeePrep || this.isAdmin
  // }
  //
  // get canView() {
  //   return this.isAdvisor
  // }
  //
  // get canManageCurriculum() {
  //   return this.isAdmin || this.isCurriculumCoordinator
  // }
}
