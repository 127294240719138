import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  kids: []
};

export const UserMapSlice = createSlice({
  name: 'useMAp',
  initialState,
  reducers: {
    setKidsMap: (state, action) => {
      state.kids = action.payload;
    },
    // get: (state, action) => {
    //   state.users = action.payload;
    // },
    // undoImpersonateUser: (state) => {
    //   state.impersonatedUserId = '';
    //   state.impersonatedUserEmail = '';
    // },
  },
});

export const { setKidsMap } = UserMapSlice.actions;


export default UserMapSlice.reducer;
